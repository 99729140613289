import React from 'react';

import Layout from '../components/Layout';
import config from '../../config';

import Scroll from '../components/Scroll';
import { Link } from 'gatsby';

// import churchPic from '../assets/images/churchOfBetterDaze.png';
import typingDna from '../assets/images/dna.jpg';
import practiceDrones from '../assets/images/musicianDrones.png';
// import boyGolden from '../assets/images/boyGolden.png';
import snowboardStore from '../assets/images/snowboardStore.png';
import triviaGame from '../assets/images/trivia.png';
// import movieApp from '../assets/images/movieApp.png';
// import burgerBar from '../assets/images/burgerBar.png';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h1>{config.heading}</h1>
        <h2>{config.subHeading}</h2>
        <div className="social-wrapper">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <div key={url} className="social-icon">
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </div>
          );
        })}
        </div>
        <ul className="actions special">
          {/* <li>
            <Scroll type="id" element="experience">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li> */}
        </ul>
      </div>
      <Scroll type="id" element="experience">
        <a href="#about" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <div id="experience">
      <h3 className="section-title">Experience</h3>
      <section className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h4>
              7shifts - Full Stack Web Developer 
              <span className="dates">(Oct. 2021 - Current)</span>
            </h4>
           
            <hr/>
            <br/>
            <h4>
            PremiseHQ SaaS Inc. - Junior Software Engineer  <span className="dates">(April 2021 - Oct. 2021)</span>
            </h4>
            
            <hr/>
            <br/>
            <h4>
            Freelance Full Stack / Web Developer <span className="dates">(Dec. 2020 - Current)</span>
            </h4>
           
            <hr/>
            <br/>
            <p style={{ textAlign: 'left', lineHeight: '2.5rem' }}>

            </p>
          </header>
        </div>
      </section> 
    </div>
    <div id="tech-stack">
      <h3 className="section-title">Technical Skills</h3>
      <section className="wrapper style1 special">
        <div className="inner">
          <ul
            className=" major tech-stack"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <li>
              <h3>Frontend</h3>
              <p>
                Javascript, React, Gatsby, Next.js, HTML, CSS, SCSS, Material UI,
                Bootstrap, Tailwind, Sanity, Laravel, PHP 
              </p>
              <hr />
            </li>

            <li>
              <h3>Backend</h3>
              <p>
                Javascript, PHP, Node, Express, PostgreSQL, MongoDB, Graphql, Firebase / Firestore, Rails, Cloudflare, Netlify, Heroku 
              </p>
              <hr />
            </li>
            <li>
              <h3>Additional Tools</h3>
              <p>
                Git, Github, Bitbucket, GA4, GTM, Optimizely, Osano, Jest, Mocha, Chai, 
              </p>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <section id="projects" className="wrapper alt style2">
      <h3 className="section-title">Projects</h3>

      <section className="spotlight">
        <div className="image">
          <img src={typingDna} alt="" />
        </div>
        <div className="content">
          <a href="https://devpost.com/software/dnaducation" target="_blank">
            <h3>Typing DNA - Educational Assessment Portal</h3>
          </a>
          <p>
            DNAducation utilizes advanced TypingDNA biometrics to provide an
            educational portal that monitors student identity via typing
            throughout the examination process. Administrators can create and
            mark exams and students can take their exams and access grades
            through the portal. Built as part of a DevPost Hackathon.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a
                href="https://devpost.com/software/dnaducation"
                target="_blank"
              >
                DevPost
              </a>
            </button>
            {/* <button className="button primary">
              <a href="https://dnaducation.herokuapp.com/" target="_blank">
                Visit Site
              </a>
            </button> */}

            <button className="button primary">
              <a
                href="https://github.com/skendanavian/DNAducation"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <br />
          <p className="stack-text">
            React, Node, Express, MaterialUI, HTML, CSS, PostgreSQL, Knex,
            Axios, TypingDNA, Heroku
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={snowboardStore} alt="" />
        </div>
        <div className="content">
          <a
            href="https://github.com/skendanavian/E-Commerce-Shopping-Cart"
            target="_blank"
          >
            <h3>React Mountain Sports - Snowboard Store</h3>
          </a>
          <p>
            Online snowboard store project inspired by the origins of Shopify.
            Includes filtering of inventory, shopping cart, checkout, and
            administrator access to view pending and filled orders. Admin
            authentication uses the Auth0 api.
          </p>
          <br />
          <div className="link-row">
            {/* <button className="button primary">
              <a
                href="https://react-mountain-sports-test.herokuapp.com/"
                target="_blank"
              >
                Visit Site
              </a>
            </button> */}

            <button className="button primary">
              <a
                href="https://github.com/skendanavian/E-Commerce-Shopping-Cart"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <br />
          <p className="stack-text">
            React, Node, Express, Redux, HTML, CSS, MongoDB, Auth0, Heroku
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={triviaGame} alt="" />
        </div>
        <div className="content">
          <a
            href="https://github.com/skendanavian/lets-get-trivial"
            target="_blank"
          >
            <h3>Let's Get Trivial - Multiplayer Trivia Game </h3>
          </a>
          <p>
            Multiplayer trivia game with fully customizable question categories,
            game settings (including sound effects), and fun animations. Users
            can create private or public rooms and play against friends by
            sharing a game code. You can also play solo rounds to hone your
            trivia skills. The app utilizes Socket.io and the OTDB api.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a
                href="https://heartfelt-sfogliatella-46e661.netlify.app/"
                target="_blank"
              >
                Visit Site
              </a>
            </button>
            <button className="button primary">
              <a
                href="https://github.com/skendanavian/lets-get-trivial"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <p className="stack-text">
            React, Node, Express, Socket.io, HTML, Sass, OTDB, Heroku (Hosting)
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={practiceDrones} alt="" />
        </div>
        <div className="content">
          <a href="https://www.musicianpracticedrones.com" target="_blank">
            <h3>Musician Pratice Drones </h3>
          </a>
          <p>
            A tool for musicians to practice intonation and improvisation. The
            app enables the user to create a highly customizable audio landscape
            built from any combination of the 12 pitches found in western music.
            In addition to designing the user interface, I also did all of the
            sound design using Ableton Live.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a href="https://www.musicianpracticedrones.com" target="_blank">
                Visit Site
              </a>
            </button>
            <button className="button primary">
              <a
                href="https://github.com/skendanavian/musician-practice-drones"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <p className="stack-text">
            React, CSS, HTML, Netlify (Hosting), Ableton Live
          </p>
        </div>
      </section>
{/* 
      <section className="spotlight">
        <div className="image">
          <img src={churchPic} alt="" />
        </div>
        <div className="content">
          <a href="https://www.churchofbetterdaze.com" target="_blank">
            <h3>The Church of Better Daze</h3>
          </a>
          <p>
            A quirky 90s inspired website designed to promote the release of
            musician Boy Golden's latest album{' '}
            <em>The Church of Better Daze</em>. Includes a blog with CMS for
            client editing. If you miss the days of marquees, Arial fonts, GIFs
            and unstyled buttons... you've come to the right place.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a href="https://www.churchofbetterdaze.com" target="_blank">
                Visit Site
              </a>
            </button>
          </div>
          <p className="stack-text">
            React, Gatsby, HTML, CSS, Mailchimp, Netlify (CMS, Hosting, Forms)
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={burgerBar} alt="" />
        </div>
        <div className="content">
          <a href="https://github.com/skendanavian/Burger-Bar" target="_blank">
            <h3>Burger Bar - Restaurant Take-out App </h3>
          </a>
          <p>
            Burger Bar project is a full take-out management system for
            restaurant orders. Customers can view the menu and add items and
            specific instructions to accompany their order. Once an order is
            successfully placed, the kitchen is notified via the admin page and
            the customer is sent an automated text message providing their
            estimated order pickup time. They will recieve an additional message
            when the order is ready for pickup and the kitchen admin data is
            then updated.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a
                href="https://github.com/skendanavian/Burger-Bar"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <p className="stack-text">
            Node, Express, HTML, SASS, jQuery, PostgreSQL, Axios, Twilio
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={boyGolden} alt="" />
        </div>
        <div className="content">
          <a href="https://www.boygolden.ca" target="_blank">
            <h3>Boy Golden - 'Myspace' Page </h3>
          </a>
          <p>
            Another throwback to the 90s with this one pager inspired by the
            wonderful and shortlived era of Myspace. This was built to spec for
            musician Boy Golden to accompany his <em>Church of Better Days</em>{' '}
            album website and is full of easter eggs.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a href="https://www.boygolden.ca/" target="_blank">
                Visit Site
              </a>
            </button>
          </div>
          <p className="stack-text">
            React, Gatsby, CSS, HTML, Netlify (Hosting)
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={movieApp} alt="" />
        </div>
        <div className="content">
          <a href="https://movie-awards-app.netlify.app/" target="_blank">
            <h3>Movie Awards Nomination App </h3>
          </a>
          <p>
            Movie awards app where users can live search the{' '}
            <em>Open Movie Database</em> to find movies to nominate. Once they
            have nominated 5 unique movies their choices can be submitted. Built
            as part of the Shopify internship challenge.
          </p>
          <br />
          <div className="link-row">
            <button className="button primary">
              <a href="https://movie-awards-app.netlify.app/" target="_blank">
                Visit Site
              </a>
            </button>
            <button className="button primary">
              <a
                href="https://github.com/skendanavian/Movie-Award-Nomination-App"
                target="_blank"
              >
                Github
              </a>
            </button>
          </div>
          <p className="stack-text">
            React, CSS, HTML, Axios, OMDB API, Netlify (Hosting)
          </p>
        </div>
      </section> */}
    </section>

    {/* <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h3>Accumsan mus tortor nunc aliquet</h3>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec amet imperdiet
            eleifend
            <br />
            fringilla tincidunt. Nullam dui leo Aenean mi ligula, rhoncus
            ullamcorper.
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-paper-plane">
            <h3>Arcu accumsan</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>Ac Augue Eget</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>Mus Scelerisque</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-headphones-alt">
            <h3>Mauris Imperdiet</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Aenean Primis</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-flag">
            <h3>Tortor Ut</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
        </ul>
      </div>
    </section> */}

    {/* <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h3>Arcue ut vel commodo</h3>
          <p>
            Aliquam ut ex ut augue consectetur interdum endrerit imperdiet amet
            eleifend fringilla.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </section> */}
  </Layout>
);

export default IndexPage;
